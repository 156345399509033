import React from 'react';
import { Button } from "components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "components/ui/card";
import { Badge } from "components/ui/badge";
import { PenSquare, Zap } from "lucide-react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from "components/ui/dialog";
import ComplianceAgent from "./create-product-agent";
import CreateProductAgent from './create-product-agent';

export default function MultiAgentsContent() {
  return (
    <div className="min-h-screen">
      <main className="max-w-7xl mx-auto">
        <div className="mb-12 mt-6">
          <h3 className="text-xl font-semibold mb-2">Templates</h3>
          <p className="text-gray-600 mb-6">Kickstart your project with pre-built agents that already include AI actions and ready to be deployed.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <AgentCard
              title="Document Manager Agent"
              description="Integrates document management with CRM, facilitating dynamic financial reporting and easily linking data between sales and marketing."
              tag="Finance"
              actions={3}
              version={2}
            />
            <AgentCard
              title="Customer Service Agent"
              description="Automates responses, managing inquiries, and providing real-time assistance. Supports dynamic actions like generating follow-up messages, surfacing relevant documentation, and triggering notifications to streamline customer interactions."
              tag="CustomerService"
              actions={2}
              version={5}
            />
            <AgentCard
              title="Create Product Agent"
              description="Automates product management by integrating pricing and availability APIs, triggering stock alerts, dynamically creating follow-up actions, and surfacing relevant resources in chat. Supports CSV uploads or integration with Azure and Snowflake for catalog data."
              tag="Product"
              actions={2}
              version={3}
              isCreateProductAgent
            />
          </div>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-4">My Agents</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <AgentCard
              title="Cash Flow Optimization Agent"
              description="Generates invoices and manages receivables in SAP Concur and uses Twilio to automate payment reminders and customer communication."
              tag="Finance"
              actions={2}
              version={8}
              chat
            />
            <AgentCard
              title="Procurement Agent"
              description="Automates procurements by verifying vendor orders in Oracle ERP, ensuring budget compliance, and uses Salesforce for end-to-end visibility of supply chain activities."
              tag="Ops"
              actions={2}
              version={2}
              chat
            />
            <AgentCard
              title="Invoice Coordination Agent"
              description="Facilitates agent-to-agent communication with Cash Flow Optimization & Procurement Agents. Uses Oracle ERP, SAP Concur, and Salesforce to optimize procurement."
              tag="Finance"
              actions={3}
              version={10}
              chat
            />
          </div>
        </div>
      </main>
    </div>
  );
}

function AgentCard({ title, description, tag, actions, version, chat, isCreateProductAgent }) {
  return (
    <Card className="flex flex-col">
      <CardHeader className="space-y-2">
        <CardTitle className="text-lg font-semibold">{title}</CardTitle>
        <div className="inline-block w-auto">
          <Badge 
            variant="secondary" 
            className={`${
              tag === 'Finance' ? 'bg-green-100 text-green-800 hover:bg-green-200' :
              tag === 'Ops' ? 'bg-purple-100 text-purple-800 hover:bg-purple-200' :
              tag === 'Product' ? 'bg-blue-100 text-blue-800 hover:bg-blue-200' :
              'bg-red-100 text-red-800 hover:bg-red-200'
            }`}
          >
            #{tag}
          </Badge>
        </div>
      </CardHeader>
      <CardContent className="flex-grow">
        <p className="text-sm text-gray-600">{description}</p>
      </CardContent>
      <CardFooter className="flex justify-between items-center mt-auto">
        <div className="flex items-center space-x-2 text-sm text-gray-500">
          <Zap className="w-4 h-4" />
          <span>{actions} actions</span>
          <span>Version {version}</span>
        </div>
        {isCreateProductAgent ? (
          <Dialog>
            <DialogTrigger asChild>
              <Button variant={chat ? "outline" : "default"}>
                {chat ? "Chat" : "Create"}
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[1000px] h-[100vh] sm:max-h-[80vh] flex flex-col">
              <DialogHeader>
                <DialogTitle><h2 className="text-2xl font-semibold">Create Product Agent <PenSquare size={20} className="inline ml-2" /></h2></DialogTitle>
                <DialogDescription>
                  Configure your Create Product Agent settings here.
                </DialogDescription>
              </DialogHeader>
              <div className="flex-grow overflow-hidden">
                <CreateProductAgent />
              </div>
            </DialogContent>
          </Dialog>
        ) : (
          <Button variant={chat ? "outline" : "default"}>
            {chat ? "Chat" : "Create"}
          </Button>
        )}
      </CardFooter>
    </Card>
  );
}